<template>
  <div id="app">
    <Header />
    <div class="main-page">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Default',
});
</script>

<style lang="scss" scoped>
#app {
  font-family: $font-family-base;
  font-weight: 300;
  color: $dark;
  margin: 0;
  padding: 0;
  .main-page {
    @media (max-width: 767px),
      (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      margin-top: 64px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      margin-top: 64px;
    }
  }
  width: 100%;
  overflow-x: hidden;
}
</style>
