<template>
  <div class="footer">
    <section class="section-bg-dark">
      <div class="mx-auto px-4 pt-24 md:container md:px-8 lg:px-12">
        <div class="grid gap-16 md:grid-cols-3 lg:grid-cols-3">
          <div class="text-white">
            <NuxtLink to="/">
              <div class="logo">
                <img
                  src="https://public-assets.naamdeo.org/cdn-cgi/image/width=250,quality=90,format=auto/images/logo-white.png"
                  alt=""
                />
              </div>
            </NuxtLink>
            <div class="mt-8">
              <div class="social-icons flex flex-row">
                <NuxtLink
                  to="https://instagram.com/naam.deo"
                  target="_blank"
                  rel="noopener"
                  class="mr-6"
                  ><img
                    src="https://public-assets.naamdeo.org/images/Instagram_white.png"
                    alt="asy-instagram-link"
                /></NuxtLink>
                <NuxtLink
                  to="https://facebook.com/TheNaamdeo"
                  target="_blank"
                  rel="noopener"
                  class="mr-6"
                  ><img
                    src="https://public-assets.naamdeo.org/images/Facebook_white.png"
                    alt="asy-facebook-link"
                /></NuxtLink>
                <NuxtLink
                  to="https://youtube.com/NAAMDEO"
                  target="_blank"
                  rel="noopener"
                  class="mr-6"
                  ><img
                    src="https://public-assets.naamdeo.org/images/Youtube_white.png"
                    alt="asy-youtube-link"
                /></NuxtLink>
                <NuxtLink
                  to="https://api.whatsapp.com/send?phone=971555714269"
                  target="_blank"
                  rel="noopener"
                  class="mr-6"
                  ><img
                    src="https://public-assets.naamdeo.org/images/WhatsApp_white.png"
                    alt="asy-whatsapp-link"
                /></NuxtLink>
                <NuxtLink
                  to="https://podcasters.spotify.com/pod/show/naam-deo"
                  target="_blank"
                  rel="noopener"
                  ><img
                    src="https://public-assets.naamdeo.org/images/spotify.png"
                    alt="asy-spotify-link"
                /></NuxtLink>
              </div>
            </div>
          </div>
          <div class="text-white">
            <h5 class="mb-8 text-2xl">Quick Links</h5>
            <ul class="links">
              <li>
                <NuxtLink to="https://courses.naamdeo.org/" target="_blank">Find Yourself</NuxtLink>
              </li>
              <li><NuxtLink to="/teachings">Timeless Teachings</NuxtLink></li>
              <li><NuxtLink to="/connect">Connect</NuxtLink></li>
            </ul>
          </div>
          <div class="text-white">
            <h5 class="mb-8 text-2xl">Contact</h5>
            <div class="mt-4">
              <div class="mt-4">
                <a href="mailto:info@naamdeo.org" class="text-color-1 text-xl">info@naamdeo.org</a>
              </div>
              <div class="mt-4">
                <a href="tel:+971 55 571 4269" class="text-color-1 text-xl">+971 55 571 4269</a>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <p class="text-left text-sm text-white md:text-center">
            © {{ copyrightYear }} namedeo.org. All rights reserved.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterPage',
  computed: {
    copyrightYear() {
      return new Date().getFullYear();
    },
  },
});
</script>

<style lang="scss" scoped>
.footer {
  .logo {
    height: 48px;
    img {
      height: 100%;
    }
  }
  ul.links {
    li {
      margin-bottom: 12px;
    }
  }

  .social-icons {
    margin-top: 40px;
    img {
      width: 40px;
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .copyright {
    margin-top: 40px;
    border-top: 0.001rem solid #555;
    padding: 24px 0;
  }
}
</style>
