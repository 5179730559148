<template>
  <div class="header" :class="$route.path === '/' ? 'fixed' : 'fixed-colored'">
    <div class="px-4 lg:px-8 xl:px-32">
      <div class="flex items-center justify-between">
        <NuxtLink to="/">
          <div class="logo">
            <img
              src="https://public-assets.naamdeo.org/cdn-cgi/image/width=250,quality=90,format=auto/images/logo-white.png"
              alt=""
            />
          </div>
        </NuxtLink>
        <ul class="links hidden lg:block" :class="{ 'mobile-menu': showMenu }">
          <li>
            <NuxtLink to="https://courses.naamdeo.org/" target="_blank">Find Yourself</NuxtLink>
          </li>
          <li><NuxtLink to="/teachings">Timeless Teachings</NuxtLink></li>
          <li><NuxtLink to="/connect" class="btn">Connect</NuxtLink></li>
        </ul>
        <a class="burger-menu lg:hidden" @click="toggleMenu">
          <span class="menu-line line-top" />
          <span class="menu-line" />
          <span class="menu-line line-bottom" />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderPage',
  data() {
    return {
      showMenu: false,
    };
  },
  watch: {
    $route() {
      this.showMenu = false;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleScroll() {
      const header = document.querySelector('.header');
      if (!header) {
        return;
      }
      if (window.scrollY > 150 && !header.className.includes('scroll-view')) {
        header.classList.add('scroll-view');
      } else if (window.scrollY < 150) {
        header.classList.remove('scroll-view');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.header {
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.85s cubic-bezier(0.18, 0.32, 0.17, 0.99);
  }
  &.fixed-colored {
    background-color: $dark;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.85s cubic-bezier(0.18, 0.32, 0.17, 0.99);
  }
  z-index: 2022;
  .logo {
    height: 32px;
    img {
      height: 100%;
    }
  }

  @media (max-width: 767px),
    (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    background-color: $dark;
    padding: 12px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    .logo {
      height: 28px;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    height: 64px;
    background-color: $dark;
    padding: 12px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  ul.links {
    li {
      padding: 1.5rem 0;
      position: relative;
      display: inline-block;
      margin-right: 2rem;
      font-size: 0.825rem;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: #ffffff;
      .nuxt-link-active:not(.btn) {
        position: relative;
        &:after {
          position: absolute;
          top: calc(100% + 4px);
          left: 0;
          content: '';
          width: 100%;
          height: 1px;
          background: #ffffff;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &.dropdown {
        padding-right: 25px;
        &:after {
          content: url('https://public-assets.naamdeo.org/images/dropdown.svg');
          position: absolute;
          right: 0;
          height: 16px;
          width: 16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      ul.subnav {
        white-space: nowrap;
        height: auto;
        position: absolute;
        text-align: left;
        min-width: 200px;
        top: 100%;
        left: 0;
        visibility: hidden;
        background-color: rgba($color: $dark, $alpha: 0.8);
        li {
          display: block;
          margin-right: 0;
          line-height: 32px;
          color: #fafafa;
          padding: 8px 16px;
          cursor: pointer !important;
          &:hover {
            background-color: rgba($color: $color-1, $alpha: 0.8);
          }
        }
      }
      &:hover {
        ul.subnav {
          visibility: visible;
          z-index: 2021;
        }
      }

      @media (max-width: 767px),
        (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
        &.dropdown {
          &:after {
            top: 16px;
            transform: none;
          }
        }
        ul.subnav {
          position: relative;
          display: none;
          visibility: visible;
        }
        &:hover {
          ul.subnav {
            display: block;
          }
        }
      }
      a.btn {
        height: 40px;
        padding: 8px 16px;
        font-weight: 300;
        border-radius: 20px;
        cursor: pointer;
        background-color: $color-1;
        color: $lighter-gray;
        font-size: 0.825rem;
      }
    }
  }
  &.scroll-view {
    background-color: $dark !important;
    /* position: fixed;
        top: 0;
        left: 0;
        width: 100%; */
    transition: all 0.85s cubic-bezier(0.18, 0.32, 0.17, 0.99);
  }

  .burger-menu {
    width: 28px;
    height: 20px;
    position: absolute;
    right: 3rem;
    z-index: 10000;
    overflow: hidden;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);

    .line-top {
      margin-top: -8px;
    }
    .line-bottom {
      margin-top: 8px;
    }

    .menu-line {
      width: 100%;
      height: 2px;
      display: block;
      background-color: #fefefe;
      top: 50%;
      position: absolute;
      z-index: 5;
    }

    @media (max-width: 767px),
      (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      right: 1.5rem;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      right: 1.5rem;
    }
  }
  .mobile-menu {
    display: block;
    height: auto;
    background-color: $dark;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    ul {
      display: block;
      text-align: left;
      padding: 0 16px;
      li {
        display: block !important;
        line-height: 56px;
        margin: 0;
      }
    }
  }

  @media (max-width: 767px),
    (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    .logo {
      height: 40px;
    }
    ul.links {
      padding: 8px 16px;
      li {
        display: block;
        padding: 16px 0;
      }
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .logo {
      height: 40px;
    }
    ul.links {
      padding: 8px 16px;
      li {
        display: block;
        padding: 16px 0;
      }
    }
  }
}
</style>
